import Cover from '../components/Cover';
import { SpiikList, SpiikListItem } from '../components/SpiikList';
import { covers } from '../data/covers';

const { membership: cover } = covers;

const process = {
  one: { text: 'Navigera till appen Orbi. Länk för nedladdning: ', link: 'https://orbiapp.io/' },
  two: 'Sök på Spiik i navigationsfältet och gå in på vår sida.',
  three: 'Klicka på vårat medlemskort.',
  four: 'Klicka på "Become a member" och följ instruktionerna.',
  five: 'Vipps, så är du medlem i Sveriges bästa studentförening!',
};

const Membership = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
      </Cover>
    </section>
    <div className="container container--narrow">
      <h2>Hur blir jag medlem?</h2>
      <p>Vill du bli medlem i SPIIK? Då ska du titta hit!</p>
      <hr />
      <p>Det finns tre olika typer av medlemskap:</p>
      <SpiikList>
        <SpiikListItem>
          Campusstudenter
          <SpiikList>
            <SpiikListItem>
              1 år för 100kr
            </SpiikListItem>
            <SpiikListItem>
              2 år för 150kr
            </SpiikListItem>
            <SpiikListItem>
              3 år för 200kr
            </SpiikListItem>
          </SpiikList>
        </SpiikListItem>
        <SpiikListItem>Distansstudenter - 1 år för 50kr</SpiikListItem>
        <SpiikListItem>Stödmedlemskap - 1 år från 100kr</SpiikListItem>
      </SpiikList>

      <hr />
      <p>Du blir enkelt medlem genom att …</p>
      <ol>
        <li>
          {process.one.text}
          <a className="styled-link" target="_blank" href={process.one.link} rel="noreferrer">{process.one.link}</a>
        </li>
        <li>{process.two}</li>
        <li>{process.three}</li>
        <li>{process.four}</li>
        <li>{process.five}</li>
      </ol>

    </div>
  </>
);

export default Membership;
